<template>
  <div class="remarks">
    <lenon-list-page
      title="Assessment Settings"
      :columns="columns"
      :rows="assessmentSettings"
      :table-loading="tableLoading"
      :show-create="false"
      sort-column="class"
      sort-dir="asc"
      :show-search="true"
      :enable-multi-select="true"
      @onRowSelect="setSelectedSetting"
    >
      <template slot="right-extra-header-actions">
        <div class="d-flex align-self-center align-items-start mb-2 desktop-only">
          <lenon-button
            v-responsive="$store.getters['app/largeScreen']"
            icon="XIcon"
            label="Remove"
            tool-tip-text="Remove selected settings"
            variant="flat-danger"
            class="mr-1"
            @onClick="confirmDeletion()"
          />
          <lenon-button
            v-responsive="$store.getters['app/largeScreen']"
            icon="PlusIcon"
            label="Setting"
            tool-tip-text="Create assessment setting"
            class=""
            @onClick="showRemarkSetupModal()"
          />
        </div>
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="d-flex align-self-center align-items-start"
        >
          <lenon-dropdown left>
            <lenon-dropdown-item
              icon="XIcon"
              text="Remove"
              @click="confirmDeletion()"
            />
            <lenon-dropdown-item
              icon="PlusIcon"
              text="Setting"
              @click="showRemarkSetupModal()"
            />
          </lenon-dropdown>
        </div>
      </template>
      <template #index="{row}">
        {{ row.index + 1 }}
      </template>
    </lenon-list-page>

    <assessment-settings
      :modal-opened="assessmentSettingModalOpened"
      @modalClosed="closeModal('assessmentSetting')"
    />
  </div>
</template>

<script>
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import { FETCH_ASSESSMENT_SETTINGS_Q } from '@/graphql/queries'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import AssessmentSettings from '@/views/admin/academics/assessment-settings/AssessmentSettings.vue'
import { DELETE_ASSESSMENT_SETTINGS } from '@/graphql/mutations'
import confirm from '@/lenon/mixins/confirm'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'
import LenonDropdown from '@/lenon/components/LenonDropdown'

export default {
  name: 'RemarkList',
  components: {
    LenonDropdown,
    LenonDropdownItem,
    AssessmentSettings,
    LenonListPage,
    LenonButton,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      assessmentSettingModalOpened: false,
      tableLoading: false,
      selectedSettings: [],
      columns: [
        {
          key: 'multiselect',
          label: '',
        },
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'class',
          label: 'Class',
        },
        {
          key: 'subject',
          label: 'Subject',
        },
        {
          key: 'class_score_percentage',
          label: 'Class Score %',
        },
        {
          key: 'exam_score_percentage',
          label: 'Exam Score %',
        },
      ],
    }
  },
  computed: {
    assessmentSettings() {
      return this.$store.getters['assessmentSettings/assessmentSettings']
    },
  },
  mounted() {
    this.fetchAssessmentSettings()
  },
  methods: {
    setSelectedSetting(items) {
      this.selectedSettings = items.map(row => row.id)
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'assessmentSetting':
          this.assessmentSettingModalOpened = false
          break
      }
    },
    showRemarkSetupModal() {
      this.assessmentSettingModalOpened = true
    },
    fetchAssessmentSettings() {
      // this.tableLoading = true
      this.$apollo.query({ query: FETCH_ASSESSMENT_SETTINGS_Q }).then(res => {
        this.tableLoading = false
        this.$store.commit('assessmentSettings/setAssessmentSettings', res.data.assessmentSettings)
      }).catch(err => {
        this.tableLoading = false
      })
    },
    confirmDeletion() {
      if (Object.keys(this.selectedSettings).length > 0) {
        this.confirmAction('Please confirm you want to delete selected assessment wallet, a default of 60/40 for exam and class scores respectively will be used.').then(res => {
          if (res) {
            this.deleteAssessmentSettings()
          }
        })
      } else {
        this.showInfo('Select at lease one setting')
      }
    },
    deleteAssessmentSettings() {
      this.tableLoading = true
      this.$apollo.query({ query: DELETE_ASSESSMENT_SETTINGS, variables: { ids: this.selectedSettings } }).then(res => {
        this.tableLoading = false
        console.log(res)
        if (!res.errors) {
          this.$store.commit('assessmentSettings/removeAssessmentSettings', this.selectedSettings)
          this.showSuccess('Deleted successfully')
        } else {
          this.showError('Something went wrong, please try again')
        }
      }).catch(err => {
        this.tableLoading = false
        this.showError('Something went wrong, please try again')
      })
    },
  },
}
</script>
