<template>
  <!--    Grading System-->
  <validation-observer
    ref="assessmentSettingsForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="'Create Assessment Setting'"
      :show="assessmentSettingModalOpened"
      extra-classes="grading-system"
      size="md"
      :show-overlay="assessmentSettingLoading"
      @onClose="closeAssessmentSettingModal()"
    >
      <error-display :error="error" />
      <b-row
        v-for="(assessmentSetting,index) in assessmentSettings"
        :key="index"
      >
        <div class="col-12">
          <strong>Setting {{ index+1 }}</strong>
        </div>
        <div class="col-12 mt-2">
          <lenon-multi-select
            v-model="assessmentSetting.classes"
            name="classes"
            label-name="name"
            value-name="id"
            label="Affected Classes"
            placeholder="Select Classes"
            :options="classes"
            rules="required"
          />
        </div>
        <div class="col-12">
          <b-form-checkbox
            class="mt-1"
            @change="(checked)=>checkAll(checked,index)"
          >
            Select All Classes
          </b-form-checkbox>
        </div>
        <div class="col-12 mt-2">
          <lenon-multi-select
            v-model="assessmentSetting.subjects"
            name="subjects"
            label-name="name"
            value-name="id"
            label="Select Subjects"
            :options="subjects"
            rules="required"
          />
        </div>
        <div class=" col-12 mb-1">
          <b-form-checkbox
            class="mt-1"
            @change="(checked)=>checkAll(checked,index,'subjects')"
          >
            Select All Subjects
          </b-form-checkbox>
        </div>
        <div class="col-md-5">
          <lenon-input
            v-model="assessmentSetting.class_score_percentage"
            name="class_percentage"
            placeholder="Class Score %"
            label="Class Score %"
            rules="required"
            :suffix-icon="null"
          />
        </div>
        <div class="col-md-5">
          <lenon-input
            v-model="assessmentSetting.exam_score_percentage"
            name="exam_percentage"
            placeholder="Exam Score %"
            label="Exam Score %"
            rules="required"
            :suffix-icon="null"
          />
        </div>
        <div class="col-md-2 mt-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item
              :disabled="assessmentSettings.length===1"
              @click="removeAssessmentSetting(i)"
            >
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
      </b-row>
      <div class="float-right mt-2">
        <lenon-button
          variant="outline-primary"
          icon="PlusIcon"
          label=""
          tool-tip-text="Add a assessmentSetting"
          class=""
          @onClick="addAssessmentSetting()"
        />
      </div>
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeAssessmentSettingModal()"
          />
          <lenon-button
            label="Create"
            :disabled="invalid"
            :loading="assessmentSettingLoading"
            loading-text="Loading..."
            @onClick="createAssessmentSetting()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BDropdownItem, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
// eslint-disable-next-line import/extensions
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect.vue'
import {
  CREATE_ASSESSMENT_SETTING,
} from '@/graphql/mutations'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'

export default {
  name: 'AssessmentSettings',
  components: {
    ErrorDisplay,
    LenonDropdown,
    LenonMultiSelect,
    LenonInput,
    LenonButton,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BFormCheckbox,
  },
  mixins: [showToast],
  props: {
    modalOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      error: {},
      assessmentSettingModalOpened: false,
      assessmentSettingLoading: false,
      assessmentSettings: [{
        class_score_percentage: null,
        exam_score_percentage: null,
        classes: [],
        subjects: [],
      }],
    }
  },
  computed: {
    subjects() {
      return this.$store.getters['studentGrading/subjects']
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
  },
  watch: {
    modalOpened(opened) {
      this.assessmentSettingModalOpened = opened
    },
  },
  methods: {
    checkAll(checked, index, type = 'classes') {
      if (checked) {
        this.assessmentSettings[index][type] = this[type]
      } else {
        this.assessmentSettings[index][type] = []
      }
    },
    closeAssessmentSettingModal() {
      this.assessmentSettingModalOpened = false
      this.$emit('modalClosed')
    },
    resetAssessmentSettingForm() {
      this.assessmentSettings = [{
        class_score_percentage: null,
        exam_score_percentage: null,
        classes: [],
        subjects: [],
      }]
      this.$refs.assessmentSettingsForm.reset()
    },
    addAssessmentSetting() {
      this.assessmentSettings.push({
        class_score_percentage: null,
        exam_score_percentage: null,
        classes: [],
        subjects: [],
      })
    },
    removeAssessmentSetting(i) {
      this.assessmentSettings.splice(i, 1)
    },
    createAssessmentSetting() {
      this.error = {}
      this.assessmentSettingLoading = true
      const assessmentSettings = this.assessmentSettings.map(setting => ({
        ...setting,
        class_score_percentage: +setting.class_score_percentage,
        exam_score_percentage: +setting.exam_score_percentage,
        subjects: setting.subjects.map(subject => subject.id),
        classes: setting.classes.map(cls => cls.id),
      }))
      this.$apollo.mutate({
        mutation: CREATE_ASSESSMENT_SETTING,
        variables: { input: { assessment_settings: assessmentSettings } },
      }).then(res => {
        this.assessmentSettingLoading = false
        this.$store.commit('assessmentSettings/setAssessmentSettings', res.data.createAssessmentSetting)
        this.showSuccess('Created Successfully')
        this.resetAssessmentSettingForm()
      }).catch(err => {
        this.error = err
        this.assessmentSettingLoading = false
        console.log(err)
        this.showError('Something went wrong, please try again')
      })
    },
  },
}
</script>
